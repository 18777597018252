
import api from '@/services/api'

export default {
    //Get all screens
    allScreens(){
        return api().get('Screen')
    },
    //Add New Screen
    addScreen(data){
        return api().post('Screen',data)
    },
    //Get single screen
    getScreen(id){
        return api().get('Screen/'+id)
    },
    // Returns the delivery type
    getScreenGroup(id) {
        return api().get('Screen/GetScreenGroup/?screenGroupId='+id)
    },
    // Returns the screen group id
    getScreenGroupByScreen(screenId) {
        return api().get('Screen/GetScreenGroupByScreen?screenId='+screenId)
    },
    //Edit screen
    editScreen(data){
        return api().put('Screen/',data)
    },
    //Get MOs For screen
    getMOList(){
        return api().get('Screen/GetMediaOwners')
    },
    //Get publish types
    getPublishTypes(){
        return api().get('Screen/GetPublishTypes')
    },
    //Get machine types
    getMachineTypes(){
        return api().get('Screen/GetMachineTypes')
    },
    //Get OS Types
    getOSTypes(){
        return api().get('Screen/GetOperatingSystems')
    },
    //Get Player Software
    getPlayerSoftware(){
        return api().get('Screen/GetPlayerSoftwares')
    },
    //Get Motion Support
    getMotionSupport(){
        return api().get('Screen/GetMotionSupports')
    },
    //Get field notes
    getFieldNotesPerScreen(id){
        return api().get('Screen/GetFieldNotes/'+id)
    },
    //edit field note
    editFieldNote(data){
        return api().put('Screen/UpdateFieldNote', data)
    },
    //add field note
    addFieldNote(data){
        return api().post('Screen/AddFieldNote',data)        
    },
    //Delete field note
    deleteFieldNote(data){
        return api().post('Screen/RemoveFieldNote',data)        
    },    
    //Delete Screen By id 
    deleteScreenById(id){
        return api().delete('Screen/'+id)  
    },
    //search formats
    searchFormats(query){
        return api().get('Screen/?searchString='+query);
    },
    //Get supported media
    getSupportedMedia(id){
        return api().get('Screen/GetSupportedMedia/'+id)
    },
    //remove supported media from screen
    removeSupportedMedia(data){
        return api().post('Screen/RemoveSupportedMedia',data)
    },  
    addSupportedMedia(data){
        return api().post('Screen/AddSupportedMedia',data)
    },  
    //get child screeens
    getChildScreens(id){
        return api().get('Screen/Children/'+id)
    },
    //get screen usage by screen id
    getScreenUsage(screenId){
        return api().get('Screen/GetCampaignBurstScreensUsage?screenId='+screenId)
    },
    // Upload a maste location sheet
    uploadMasterLocations (data) {
        return api().post('Screen/UploadMasterLocations', data)
    },
    // Upload a master referenceSheet
    uploadMasterReferenceList (data) {
        return api().post('Screen/UploadMasterReferenceList', data)
    },
}
 