export default {
    methods: {
        getVuetifyColor(key) {
            if(this.$vuetify.theme.dark) {
                return this.$vuetify.theme.themes.dark[key]
            }

            return this.$vuetify.theme.themes.light[key]
        },
    }
}